.drug-label-content {
  font-size: 14px;
}

.drug-label-content h2, h3, h4, h5, h6 {
  font-size: 14px;
  padding: 0px;
  margin: 0px;
}

.drug-label-content p {
  font-size: 14px;
  padding: 0px;
  margin: 0px;
}

.drug-label-content img {
  max-width: 100%;
}

.drug-label-content table {
  width: 100%;
  overflow-x: auto;
}

.drug-label-content table td {
  width: max-content;
}

.drug-label-content ul {
  margin: 0;
  list-style: disc;
}
