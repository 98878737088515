html,
body,
#__next,
#__next > div {
  margin: 0;
}

[class*='stickyHeader'] {
  overflow-x: scroll;
  scrollbar-width: none;
}

[class*='stickyHeader']::-webkit-scrollbar {
  width: 0;
  height: 0;
}
