@tailwind base;
@tailwind components;
@tailwind utilities;

/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
*/

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
}

html {
  height: 100%;
}

* {
  font-family: Mulish;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

.clamp-content-div div {
  display: inline;
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
  align-items: stretch;
}

.clamp-content-div div::after {
  content: ' \A\A';
  white-space: pre;
}

.clamp-content-p p {
  display: inline;
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
  align-items: stretch;
}

.clamp-content-p p::after {
  content: ' \A\A';
  white-space: pre;
}

.notes-box-disabled-safari {
  opacity: 1;
  -webkit-text-fill-color: theme(colors.neutral-dark);
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

button {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  padding: 0;
}

ul {
  margin: 0;
  padding-left: 24px;
}

dl {
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

dt {
  float: left;
  width: 5%;
  padding: 8px 0;
  margin: 0;
}

dd {
  float: left;
  width: 95%;
  padding: 8px 0;
  margin: 0;
}

@media (min-width: 640px) {
  dt {
    width: 2.5%;
  }

  dd {
    width: 97.5%;
  }
}

.no-inline-border li {
  border-left: none !important;
}

[data-rsbs-header]::before {
  display: none !important;
}

[data-rsbs-header] {
  box-shadow: none !important;
}

.font-black {
  font-weight: 900;
}

.clamp-table table {
  display: inline-block !important;
}

.drug-label-table table tbody caption {
  display: table;
  width: 100%;
}

.drug-label-table table,
.drug-label-table th,
.drug-label-table td {
  border: 1px solid;
}

.drug-label-table table {
  border-collapse: collapse;
}

.drug-label-table caption {
  background-color: theme(colors.theme-tertiary);
  color: theme(colors.white);
  padding-top: 12px;
  padding-bottom: 12px;
}

.drug-label-table tr:nth-child(even) {
  background-color: theme(colors.neutral-light);
}
.notes-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.notes-scrollbar::-webkit-scrollbar-track {
  background-color: theme(colors.light-grey);
}

.notes-scrollbar::-webkit-scrollbar-thumb {
  background-color: theme(colors.neutral-tertiary);
  border-radius: 2px;
}

.horizontal-scrollbar::-webkit-scrollbar {
  height: 4px;
}

.horizontal-scrollbar::-webkit-scrollbar-track {
  padding: 0 8px;
  background-color: theme(colors.light-grey);
}

.horizontal-scrollbar::-webkit-scrollbar-thumb {
  background-color: theme(colors.neutral-tertiary);
  border-radius: 2px;
}

/* For WebKit browsers */
.transparent-scrollbar-h::-webkit-scrollbar {
  height: 4px; /* Adjust the height as needed */
}

.transparent-scrollbar-h::-webkit-scrollbar-track {
  padding: 0 8px;
  background: transparent;
}

.transparent-scrollbar-h::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2); /* Adjust the thumb color as needed */
  border-radius: 4px; /* Optional: Add rounded corners */
}

/* For Firefox */
.transparent-scrollbar-h {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

/* For WebKit browsers */
.hidden-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Combined rules for Firefox, IE, Edge, and other browsers */
.hidden-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  overflow: -moz-scrollbars-none; /* Older versions of Firefox */
}

.delay-500ms {
  transition-delay: 500ms;
}
